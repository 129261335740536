<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <div class="vx-row">
            <div class="vx-col w-full">
              <vx-card title="เลือกวันเวลา">
                <label>เริ่มต้น: </label>
                <flat-pickr v-model="before_datetime" placeholder="Date Time" />
                <br />
                <br />
                <label> สิ้นสุด: </label>
                <flat-pickr v-model="after_datetime" placeholder="Date Time" />
                <br />
                <br />
                <vs-button color="primary" @click="selectdate_time()"
                  >ค้นหา</vs-button
                >
              </vx-card>
            </div>
          </div>
          <br />
          <vx-card>
            <div class="vx-card__title">
              <h4>ประวัติการโยกเงิน</h4>
              <br />
            </div>
            <div class="export-table">
              <vs-table
                stripe
                pagination
                max-items="10"
                :data="agentData"
                search
              >
                <template slot="thead">
                  <vs-th> DATETIME</vs-th>
                  <vs-th> TYPE </vs-th>
                  <vs-th> AMOUNT </vs-th>
                  <vs-th> INFO </vs-th>
                  <vs-th> ADMIN </vs-th>
                  <vs-th> QR </vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>{{ tr.ag_datetime }}</vs-td>
                    <vs-td>{{ tr.ag_type }}</vs-td>
                    <vs-td>{{ tr.ag_amount }}</vs-td>
                    <vs-td>{{ tr.ag_info }}</vs-td>
                    <vs-td>{{ tr.ag_admin }}</vs-td>
                    <vs-td v-if="tr.ag_data"
                      ><vs-button
                        size="small"
                        variant="info"
                        @click="
                          () => showQR(tr.ag_data.paymentInfo[0].QRstring)
                        "
                        >QR</vs-button
                      ></vs-td
                    >
                    <vs-td v-else>-</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vx-card>
          <br />

          <vs-popup title="QR Code" :active.sync="popupQR">
            <div style="text-align: center">
              <div class="mb-1" v-html="miniQRCode"></div>
              <a :href="`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrString}`" target="_blank">กด Scan ที่นี่หาก QR Code ด้านบนไม่สามารถใช้งานได้</a>
            </div>
          </vs-popup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import axios from '../../axios'
import moment from 'moment'
import QRCode from 'qrcode-generator'

export default {
  components: {
    vSelect,
    flatPickr,
    StatisticsCardLine
  },
  data () {
    return {
      cellAutoWidth: true,
      before_datetime: moment.tz('Asia/Bangkok').format('YYYY-MM-DD'),
      after_datetime: moment
        .tz('Asia/Bangkok')
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true
      },
      agentData: [],
      addbuttonData: [],
      popupQR: false,
      qrString: ''
    }
  },
  computed: {
    miniQRCode () {
      const qr = QRCode(10, 'L')
      qr.addData(this.qrString)
      qr.make()
      return qr.createImgTag()
    }
  },
  async mounted () {
    await axios
      .get(
        `internal-transfer/logs/${this.before_datetime}/${this.after_datetime}`
      )
      .then((response) => (this.agentData = response.data))
  },
  methods: {
    async selectdate_time () {
      await axios
        .get(
          `internal-transfer/logs/${this.before_datetime}/${this.after_datetime}`
        )
        .then((response) => (this.agentData = response.data))
    },
    async showQR (qrString) {
      this.size = this.$store.state.is_touch_device ? 100 : 400
      this.popupQR = true
      this.qrString = qrString
    }
  }
}
</script>

<style scoped>
.qr-box {
  display: block;
  margin: auto;
}
</style>
